<template>
  <div class="flex flex-col min-h-[100dvh]">
    <div class="flex justify-center items-center bg-primary h-[156px]">
      <img
        :src="getImageFromAssets('images/icons/agendaLogo.svg')"
        class="self-center aspect-square w-[50px] h-[60px]"
        alt="Agenda Logo"
      />
    </div>
    <router-view v-slot="{ Component }" class="bg-[#FBFBFB] h-full grow-1">
      <transition name="route" mode="out-in">
        <component :is="Component"></component>
      </transition>
    </router-view>
    <div
      class="py-2 gap-3 items-center flex justify-center mt-auto text-[#313F52] text-[14px] font-[400]"
    >
      <router-link to="/terms-and-conditions">{{
        $t("termsAndConditions")
      }}</router-link>
      <div class="point"></div>
      <router-link to="/privacy-policy">{{ $t("privacyPolicy") }}</router-link>
      <div class="point"></div>
      <router-link to="/contact-us">{{ $t("contactUS") }}</router-link>
    </div>
    <!-- <CopyRight /> -->
  </div>
</template>

<script>
export default {
  name: "AuthLayout",
  components: {},
  data() {
    return {
      openToolTip: false
    }
  },
  methods: {}
}
</script>

<style scoped>
.point {
  @apply w-[2px] h-[2px] bg-[#D2D2CC] rounded-full;
}
</style>
