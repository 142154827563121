import mainLayout from "@/layout/mainLayout.vue"
const meetingsRoutes = [
  {
    path: "/entities/:entity_id/space/:space_id",
    meta: {
      layout: mainLayout,
      authGard: true
    },
    component: () => import("@/views/space/spaceLayout.vue"),
    children: [
      {
        path: "meetings",
        name: "meetings",
        meta: {
          layout: mainLayout,
          authGard: true
        },
        component: () => import("@/views/space/meetings/list.vue")
      },
      {
        path: "meetings/add",
        name: "addMeeting",
        component: () => import("@/views/space/meetings/add.vue"),
        meta: {
          layout: mainLayout,
          authGard: true
        }
      },
      {
        path: "meetings/edit/:meeting_id",
        name: "editMeeting",
        component: () => import("@/views/space/meetings/edit.vue"),
        meta: {
          layout: mainLayout,
          authGard: true
        }
      },
      {
        path: "meetings/:meeting_id",
        name: "meetingDetails",
        component: () => import("@/views/space/meetings/details/index.vue"),
        redirect: { name: "editMeetingBasicData" },
        meta: {
          layout: mainLayout,
          authGard: true
        },
        children: [
          {
            path: "basic_data",
            name: "editMeetingBasicData",
            component: () =>
              import("@/views/space/meetings/details/basicData.vue"),
            meta: {
              layout: mainLayout,
              authGard: true
            }
          },
          {
            path: "attendees",
            name: "editMeetingAttendees",
            component: () =>
              import("@/views/space/meetings/details/attendees.vue"),
            meta: {
              layout: mainLayout,
              authGard: true
            }
          },
          {
            path: "items",
            name: "editMeetingItems",
            component: () => import("@/views/space/meetings/details/items.vue"),
            meta: {
              layout: mainLayout,
              authGard: true
            }
          }
        ]
      },
      {
        // this meeting layout render in main layout
        path: "active-meetings/:meeting_id",
        name: "activeMeeting",
        component: () =>
          import("@/views/space/meetings/activeMeeting/index.vue"),
        meta: {
          layout: mainLayout,
          authGard: true
        },
        children: [
          {
            path: "agenda/:agenda_id",
            name: "agendaMeeting",
            component: () =>
              import("@/views/space/meetings/activeMeeting/agenda.vue"),
            meta: {
              layout: mainLayout,
              authGard: true
            }
          },
          {
            path: "agenda/:agenda_id/tasks/:task_id",
            name: "agendaTask",
            component: () =>
              import("@/views/space/meetings/activeMeeting/task.vue"),
            meta: {
              layout: mainLayout,
              authGard: true
            }
          },
          {
            path: "agenda/:agenda_id/files/:file_id",
            name: "agendaFile",
            component: () =>
              import("@/views/space/meetings/activeMeeting/file.vue"),
            meta: {
              layout: mainLayout,
              authGard: true
            }
          }
        ]
      }
    ]
  }
]

export default meetingsRoutes
