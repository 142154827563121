import { createRouter, createWebHistory } from "vue-router"
import notifications from "@/views/notifications.vue"
import mainLayout from "@/layout/mainLayout.vue"
import logoCentered from "@/layout/logoCentered.vue"
import MeetingRoutes from "./Modules/Meetings"
import NewsRoutes from "./Modules/News"
import ToolsRoute from "./Modules/Tools"
import ElectionsRoutes from "./Modules/Elections"
import SpaceRoutes from "./Modules/Space"
import EntityRoutes from "./Modules/Entity"
import Information from "./Modules/Information"
import AccountRoutes from "./Modules/Account"
import Stadio from "./Modules/Stadio"
import Member from "./Modules/Member"
import User from "./Modules/User"
import authLayout from "@/layout/authLayout.vue"
const routes = [
  ...SpaceRoutes,
  ...MeetingRoutes,
  ...NewsRoutes,
  ...ToolsRoute,
  ...ElectionsRoutes,
  ...EntityRoutes,
  ...Information,
  ...AccountRoutes,

  ...Member,
  ...Stadio,
  ...User,
  {
    path: "/demo",
    name: "demo",
    component: () => import("@/views/demo.vue"),
    meta: {
      layout: logoCentered,
      authGard: false
    }
  },
  {
    path: "/test-valid",
    name: "test",
    component: () => import("@/views/test-valid.vue"),
    meta: {
      layout: authLayout,
      authGard: false
    }
  },
  {
    path: "/notifications",
    name: "notifications",
    component: notifications,
    meta: {
      layout: mainLayout,
      authGard: true
    }
  },
  {
    path: "/offline",
    name: "offline",
    meta: {
      layout: logoCentered,
      authGard: false
    },

    component: () => import("@/views/default-pages/NoInternetPage.vue")
  },
  // For Test

  //

  {
    path: "/:pathMatch(.*)*",
    name: "notFound",
    meta: {
      layout: logoCentered,
      authGard: false
    },

    component: () => import("@/views/default-pages/404.vue")
  }
]

const router = createRouter({
  history: createWebHistory("/"),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { top: 0 }
  },
  pathToRegexpOptions: {
    sensitive: false
  }
})

router.beforeEach((to, from, next) => {
  const user = JSON.parse(localStorage.getItem("user"))
  if (to.meta.authGard && !user?.token) {
    next({ path: "/login" })
  } else {
    next()
  }
})

export default router
