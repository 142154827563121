import axios from "axios"
import router from "../router"
import { storeToRefs } from "pinia"
import moment from "moment"
export const ai = axios.create({
  baseURL:
    import.meta.env.VITE_BASE_URL && import.meta.env.VITE_BASE_URL !== "false"
      ? import.meta.env.VITE_BASE_URL + "/api/"
      : undefined
})

let isRefreshing = true
const prepareHeaders = (config, { token, memberId, lang }) => {
  config.headers = {
    ...config.headers,
    Authorization: token,
    "member-id": memberId,
    "Accept-Language": lang
  }
}
ai.interceptors.request.use(
  async (config) => {
    const { refreshToken } = useAuthStore()
    const userFromLocalStorage = JSON.parse(localStorage.getItem("user"))
    prepareHeaders(config, {
      token: userFromLocalStorage?.token,
      memberId: userFromLocalStorage?.member_id,
      lang: "ar"
    })
    if (userFromLocalStorage?.token) {
      let token = userFromLocalStorage.token
      const memberId = JSON.parse(localStorage.getItem("user")).member_id

      let tokenExpiredAfterOneMinute =
        userFromLocalStorage?.exp &&
        moment(userFromLocalStorage?.exp * 1000).diff(moment(), "minutes") <= 5
      if (tokenExpiredAfterOneMinute && isRefreshing) {
        isRefreshing = false
        await refreshToken(userFromLocalStorage.refresh_token)
        isRefreshing = true
        let newUser = JSON.parse(localStorage.getItem("user"))
        const memberId = JSON.parse(localStorage.getItem("member_id"))
        prepareHeaders(config, {
          token: newUser.token,
          memberId: memberId || userFromLocalStorage.member_id,
          lang: "ar"
        })
        return config
      }
    }
    return config
  },

  (error) => {
    return Promise.reject(error)
  }
)

ai.interceptors.response.use(
  (response) => {
    return Promise.resolve({ data: response.data.message, body: response })
  },
  async (error) => {
    if (error.response?.status === 403) {
   
      router.push("/")
      return Promise.reject(error.response.data.message.error || "ليس لديك صالحيه لهذه العمليه ")
    }
    if (
      error.response?.status == 401 &&
      router.currentRoute.value.name !== "login"
    ) {
      // alert("you not have permission")
      router.push("/login")
      localStorage.clear()
    }

    if (error.response?.status == 417) {
      agToaster(
        "error",
        error.response?.data?.message?.error ??
          "حدث خطأ ما , يرجى المحاولة مرة اخرى",
        "",
        {
          name: "phX",
          bg: "!bg-[#F24B4B]"
        }
      )
      return Promise.reject()
    }
   else {
      console.log(error)
      return Promise.reject(error.response)
    }
  }
)
