<template>
  <div class="layout-container pt-6">
    <div class="z-50 relative px-6">
      <app-header />
    </div>
    <div
      class="z-40 md:!z-40 relative layout-content-holder"
      :class="{
        'z-50': isSideMenuApps
      }"
    >
      <Suspense>
        <template #default>
          <router-view
            @refreshHero="rerenderHero"
            @showMenuLinks="SideMenuApps"
            v-slot="{ Component }"
          >
            <transition name="fade-transition">
              <component :is="Component"></component>
            </transition>
          </router-view>
        </template>
        <template #fallback>
          <div>Loading...</div>
        </template>
      </Suspense>
    </div>
  </div>
</template>

<script lang="ts" setup>
const layoutStore = useLayoutStore()
const backgroundGradient = computed(() => layoutStore.color)

// Create a ref to hold the current gradient for smooth transitions
const currentGradient = ref(
  backgroundGradient.value ||
    "linear-gradient(139deg, #EAECF0 10.51%, #DEDFE4 87.75%)"
)

// Watch for changes in the backgroundGradient and update currentGradient
watch(backgroundGradient, (newGradient) => {
  currentGradient.value = newGradient
})

console.log(backgroundGradient.value)

// You can keep your existing methods here
const rerenderHero = () => {
  // Implement your logic here
}

const isSideMenuApps = ref(false)

const SideMenuApps = (e: Boolean) => {
  isSideMenuApps.value = e
}
</script>

<style lang="scss">
.layout-container {
  position: relative;
  transition: all 0.3s linear;

  &:after {
    transition: background 0.3s linear;
    position: absolute;
    height: 174px;
    width: 100vw;
    z-index: 1;
    content: "";
    top: 0;
    left: 0;
    background: v-bind(currentGradient);
  }
}
</style>
