  import { ai } from "@/plugins/axios"
  import { useNotificationStore } from "@/store/toaster"

  async function getImage(imgSrc: string) {
    if (imgSrc?.includes("http")) return imgSrc
    try {
      let response = await ai.get(`https://dev.agenda.42.guru${imgSrc}`, {
        responseType: "arraybuffer"
      })
      // response.data is an ArrayBuffer
      // convert ArrayBuffer to Base64 string
      let Base64string = btoa(
        String.fromCharCode.apply(null, new Uint8Array(response.data))
      )
      let imgSrcBase64 = `data: image/jpg; base64, ${Base64string}`
      return imgSrcBase64
    } catch (e) {
      console.log(e)
    }
  };
  async function downloadFile(fileUrl: string , fileName: string) {
    await ai.get(fileUrl, {
      responseType: "blob"
    }).then((response: any) => {
      // Convert blob to base64
      // Create blob URL from response data
      // Get file extension from URL
      const fileExtension = fileName.split('.').pop()?.toLowerCase() || '';
      
      // Set appropriate content type based on extension
      let contentType = 'application/octet-stream'; // Default binary
      if (fileExtension === 'pdf') {
        contentType = 'application/pdf';
      } else if (['jpg', 'jpeg', 'png', 'gif'].includes(fileExtension)) {
        contentType = `image/${fileExtension}`;
      } else if (fileExtension === 'doc' || fileExtension === 'docx') {
        contentType = 'application/msword';
      } else if (fileExtension === 'xls' || fileExtension === 'xlsx') {
        contentType = 'application/vnd.ms-excel';
      } else if (fileExtension === 'txt') {
        contentType = 'text/plain';
      }
      const blob = new Blob([response.body.data], { type: contentType });
      const blobUrl = URL.createObjectURL(blob);
      
      // Create download link
      const link = document.createElement("a");
      link.href = blobUrl;
      // Ensure filename has extension
      const hasExtension = fileName.includes('.');
      const downloadName = hasExtension ? fileName : `${fileName}.${fileExtension}`;
      link.download = downloadName;
      link.style.display = "none";
      
      // Add to document, click and cleanup
      document.body.appendChild(link);
      link.click();
      setTimeout(() => {
        document.body.removeChild(link);
        URL.revokeObjectURL(blobUrl);
      }, 100); // Small delay to ensure download starts
    })
  }
  async function streamToArrayBuffer(
    stream: ReadableStream<Uint8Array>
  ): Promise<Uint8Array> {
    return new Uint8Array(await new Response(stream).arrayBuffer())
  }
  async function getPdf(fileUrl: string) {
    let response = await ai.get(fileUrl, {
      responseType: "arraybuffer",
      responseEncoding: "binary",
      headers: {
        "Content-Type": "application/pdf"
      }
    })
    console.log(response)
    let blob = new Blob([response.body.data], { type: "application/pdf" })
    let res = await blob.arrayBuffer()
    let base64 = btoa(
      new Uint8Array(res).reduce(
        (data, byte) => data + String.fromCharCode(byte),
        ""
      )
    )
    let base64Pdf = `data:application/pdf;base64,${base64}`
    let Url = URL.createObjectURL(blob)
    return { base64Pdf, Url }
  }

  // Function to show notifications using the Notification Store
  function agToaster(type, message, description, icon) {
    const notificationStore = useNotificationStore()
    notificationStore.addNotification({
      type: type,
      message: message,
      description: description,
      icon: {
        name: icon.name,
        bg: icon.bg
      }
    })
  }
  function parseJwt(token) {
    console.log(token)
    var base64Url = token.split(".")[1]
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/")
    var jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2)
        })
        .join("")
    )

    return JSON.parse(jsonPayload)
  }
  export { getImage, agToaster, parseJwt, getPdf, downloadFile }
