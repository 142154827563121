import SettingRoutes from "./settingRoutes"

import mainLayout from "@/layout/mainLayout.vue"
const EntityRoute = [
  {
    path: "/",
    name: "entities",
    meta: {
      layout: mainLayout,
      authGard: true
    },
    component: () => import("@/views/entity/list.vue")
  },

  {
    path: "/entities/create",
    name: "create_entity",
    meta: {
      layout: mainLayout,
      authGard: true
    },
    component: () => import("@/views/entity/create.vue")
  },
  {
    path: "/entities/:entity_id/spaces",
    name: "entity",
    meta: {
      layout: mainLayout,
      authGard: true
    },
    component: () => import("@/views/entity/spaces.vue")
  }
]

export default EntityRoute.concat(SettingRoutes)
