<script setup lang="ts">
import { useDebounceFn } from "@vueuse/core"

import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxLabel,
  ComboboxOption,
  ComboboxOptions
} from "@headlessui/vue"

interface Option {
  label: string
  value: string | number
  [key: string]: string | number | boolean
}
const router = useRouter()
const props = defineProps({
  options: {
    type: Array as PropType<Array<Option>>,
    default: () => []
  },
  error: {
    type: Boolean,
    default: false
  },
  disabled: {
    type: Boolean,
    default: false
  },
  modelValue: {
    type: [Array<String>, String],
    default: ""
  },
  serverFilter: {
    type: Boolean,
    default: false
  },
  required: {
    type: Boolean,
    default: false
  },
  //   multiple: {
  //     type: Boolean,
  //     default: false,
  //   },
  placeHolder: {
    type: String
  },
  customClasses: {
    type: String
  },
  loading: {
    type: Boolean,
    default: false
  },
  hideSelected: {
    type: Boolean,
    default: false
  },
  optionsContainerClasses: {
    type: String,
    default: ""
  },
  holderClass: {
    type: String,
    default: ""
  }
})
const comboboxButtonRef = ref(null)
const emit = defineEmits(["update:modelValue", "search", "change", "selected"])
const query = ref("")
const uniqueOptions = computed(() => {
  const optionsSet = new Set(props.options.map((option) => option.value))
  const allOptions =
    selectedOption.value && !optionsSet.has(selectedOption.value.value)
      ? [...props.options, selectedOption.value]
      : props.options
  return allOptions.filter(
    (option, index, self) =>
      index === self.findIndex((t) => t.value === option.value)
  )
})

const handleValueChange = (value: string) => {
  console.log("value", value)
  emit("update:modelValue", value)
}
const queryServer = useDebounceFn(() => {
  handleValueChange(query.value)
  emit("search", query.value)
}, 500)
const handleQueryChange = (
  e: Event & {
    target: HTMLInputElement
  }
) => {
  query.value = e.target.value
  if (
    (props.serverFilter && query.value.length >= 3) ||
    query.value.length == 0
  )
    queryServer()
}
const selectedOption = ref<Option | null>(null)
const displayOption = (value: string | string[]) => {
  if (props.hideSelected) return ""
  if (Array.isArray(value))
    return value
      .map(
        (value) =>
          uniqueOptions.value.find((option) => value === option.value)?.label ||
          ""
      )
      .join(", ")
  selectedOption.value = uniqueOptions.value.find(
    (option) => value === option.value
  )
  if (selectedOption.value?.label) return selectedOption?.value?.label || ""
  return query.value
}
const triggerClick = () => {
  comboboxButtonRef.value?.el.click()
}
</script>

<template>
  <div class="h-[44px] flex items-center" :class="holderClass">
    <slot
      name="prepend-inner"
      :hasError="false"
      :selectedOption="selectedOption"
    ></slot>
    <Combobox
      :model-value="props.modelValue"
      :disabled="disabled"
      as="div"
      @update:model-value="handleValueChange"
      class="w-full flex items-center"
      v-slot="{ open }"
    >
      <!-- <ComboboxLabel class="block text-sm font-medium text-gray-700">
      <slot /><span v-if="props.required" class="text-red-600">*</span>
    </ComboboxLabel> -->
      <div class="mt-1 w-full">
        <ComboboxInput
          :class="[
            'placeholder:md:text-lg placeholder:text-sm placeholder:text-[#313F52]/30 text-sm md:text-lg w-full border-0 rounded-none',
            { 'error-input': props.error },
            customClasses
          ]"
          :display-value="displayOption"
          :placeholder="$t(`${placeHolder}`)"
          @change="handleQueryChange($event)"
          @blur="$attrs?.onblur"
          @click="triggerClick"
        />
        <ComboboxButton ref="comboboxButtonRef">
          <!-- loader in end of input  -->
          <!-- <div v-if="loading" class=" ">
          <svg
            class="w-5 h-5 text-blue-500 animate-spin"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              class="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              stroke-width="4"
            />
            <path
              class="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            />
          </svg>
        </div> -->
        </ComboboxButton>
        <ComboboxOptions
          v-if="uniqueOptions?.length"
          :class="optionsContainerClasses"
          class="absolute top-[96px] start-0 z-10 w-full overflow-auto rounded-[8px] border-[1px] border-[rgba(12,27,35,0.08)] bg-[#FFF] shadow-[0px_10px_10px_-5px_rgba(15,17,20,0.06),_0px_-12px_20px_-5px_rgba(15,17,20,0.08)] max-h-60"
          @focusout="
            (e) => {
              if (!e.relatedTarget?.closest('.combobox-options')) {
                $emit('update:modelValue', null)
              }
            }
          "
        >
          <ComboboxOption
            v-for="filteredOption in uniqueOptions"
            :key="filteredOption.value"
            v-slot="{ selected }"
            :value="filteredOption.value"
            as="template"
          >
            <li
              class="relative p-3 cursor-pointer overflow-hidden text-gray-900 hover:bg-[#fff0c84d]"
              :class="{ 'bg-[#fff0c84d]': selected }"
              @click="
                () => {
                  $emit('update:modelValue', filteredOption.value)
                }
              "
            >
              <span
                class="block truncate"
                :class="{ 'font-semibold': selected }"
              >
                <slot name="option" :option="filteredOption">
                  {{ filteredOption.label }}
                </slot>
              </span>
            </li>
          </ComboboxOption>
        </ComboboxOptions>
      </div>
      <slot name="append-inner" :open="open"></slot>
      <ag-base-icon
        :name="open ? 'PhCaretUp' : 'PhCaretDown'"
        type="circle"
        class="me-0"
        v-if="!serverFilter"
        @click="triggerClick()"
      />
    </Combobox>
  </div>
</template>
